







































import {Component, Vue} from 'vue-property-decorator';
import {getPtOrders} from '@/api/courseOrder';
import PtSignInList from '@/views/coaches/personal-training/components/PtSignInList.vue'

@Component({
  components: {
    PtSignInList
  }
})
export default class UserBuyPt extends Vue {
  private orderDto = new Array()
  private courseSignIn = false
  private orderId = ''

  private async activated() {
    await this.getOrderList()
  }

  private async getOrderList() {
    const result = await getPtOrders()
    this.orderDto = result
  }

  private handleClickState(item: any) {
    if (item.orderState === 0) {
      this.$router.push({
        path: '/user/pt-order',
        query: {
          orderId: item.id
        }
      });
    } else {
      this.orderId = item.id
      this.courseSignIn = true
    }
  }

  private getCourseStatus(item: any) {
    let result = ''
    if (item.orderState === 0) {
      result = '未付款'
    } else if (item.orderState === -2) {
      result = '已退款'
    } else if (item.orderState === 1) {
      result = '上课记录'
    }

    return result
  }

  private handleClose() {
    this.courseSignIn = false
  }
}
