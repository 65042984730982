import request from '@/utils/request'

const url = '/keep-fit/courseOrderRecord'

export default class RecordApi {
  public async orderSignInAsync(orderId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}`,
        method: 'post',
        data: {
          orderId
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

  public async getOrderSignInAsync(orderId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}`,
        method: 'get',
        params: {
          orderId
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}
