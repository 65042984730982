











import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import RecordApi from '@/api/courseOrderRecord'

@Component({})
export default class PtSignIn extends Vue {
  @Prop({default: () => false}) private show!: boolean
  @Prop({default: () => ''}) private orderId!: string

  private api = new RecordApi()
  private currentShow = this.show
  private currentOrderId = ''
  private records = new Array()

  @Watch('orderId', {immediate: true})
  public async getOrderId(vl: string, ov: string) {
    if (vl !== ov) {
      this.currentOrderId = vl
      await this.getSignList()
    }
  }

  private handleBeforeClose(action: any, done: any) {
    this.handleClose()
  }

  private async getSignList() {
    this.records = await this.api.getOrderSignInAsync(this.currentOrderId)
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}
